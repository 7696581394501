import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import Notification from '../Notifications/Notification';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const maxNotifications = 3;
  const notificationCounter = useRef(0);

  const addNotification = useCallback((message, type = 'info', duration = 3000) => {
    const id = `${Date.now()}-${notificationCounter.current++}`;
    setNotifications(prev => {
      const updatedNotifications = [...prev, { id, message, type, isVisible: true }];
      return updatedNotifications.slice(-maxNotifications);
    });

    setTimeout(() => {
      setNotifications(prev =>
        prev.map(notif =>
          notif.id === id ? { ...notif, isVisible: false } : notif
        )
      );
    }, duration);

    // Remove the notification from state after animation completes
    setTimeout(() => {
      setNotifications(prev => prev.filter(notif => notif.id !== id));
    }, duration + 300); // 300ms for slide-out animation
  }, []);

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <div className="fixed inset-0 pointer-events-none z-50">
        <div className="flex flex-col items-end justify-start p-6 pt-[80px] pr-0 space-y-2">
          {notifications.map(({ id, message, type, isVisible }) => (
            <Notification key={id} message={message} type={type} isVisible={isVisible} />
          ))}
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};