import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import { NotificationProvider } from './components/Notifications/NotificationContext';
import { isSignInWithEmailLink } from "firebase/auth";
import { auth } from './firebaseConfig';
import logger from './utils/logger';
import ErrorBoundary from './components/ErrorBoundary';
import './App.css';

// Lazy load components
const Home = lazy(() => import('./components/HomePage/Home'));
const PromptBuilderContainer = lazy(() => import('./containers/PromptBuilderContainer'));
const Login = lazy(() => import('./components/Login/Login'));
const BuyCredits = lazy(() => import('./components/BuyCreditsPage/BuyCredits'));
const PaymentSuccess = lazy(() => import('./components/BuyCreditsPage/PaymentSuccess'));
const AuthCallback = lazy(() => import('./components/AuthCallback'));
const TermsOfService = lazy(() => import('./components/Legal/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./components/Legal/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('./components/Legal/CookiePolicy'));
const ErrorPage = lazy(() => import('./components/ErrorPage'));
const TutorialLayout = lazy(() => import('./components/Tutorials/TutorialLayout'));
const TutorialContent = lazy(() => import('./components/Tutorials/TutorialContent'));

// Improved Loading component
const Loading = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-prompt-bg bg-opacity-75 z-50">
    <div className="text-center">
      <div className="inline-block animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-prompt-white mb-4"></div>
      <div className="text-prompt-white text-lg font-medium">Loading...</div>
    </div>
  </div>
);

function AppContent() {
  const { isAuthenticated, isLoading, handleEmailLinkSignIn } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isInMaintenanceMode, setIsInMaintenanceMode] = useState(true);
  const [maintenanceContent, setMaintenanceContent] = useState('');

  const checkMaintenanceMode = async () => {
    try {
      const response = await fetch('/api/maintenance-check');
      if (response.status === 503) {
        const content = await response.text();
        setMaintenanceContent(content);
      } else {
        setIsInMaintenanceMode(false);
      }
    } catch (error) {
      setIsInMaintenanceMode(false);
    }
  };



  useEffect(() => {
    checkMaintenanceMode();
  }, []);



  useEffect(() => {
    const handleEmailLink = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }
        try {
          await handleEmailLinkSignIn(email, window.location.href);
          window.localStorage.removeItem('emailForSignIn');
          navigate('/builder');
        } catch (error) {
          logger.error('Error signing in with email link:', error);
        }
      }
    };

    handleEmailLink();
  }, [handleEmailLinkSignIn, navigate]);






  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('userCredits');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);






  useEffect(() => {
    if (location.pathname === '/') {
      document.body.classList.add('home-page');
    } else {
      document.body.classList.remove('home-page');
    }
  }, [location]);

  if (isLoading || isInMaintenanceMode === null) {
    return <Loading />;
  }

  if (isInMaintenanceMode) {
    return (
      <div 
        dangerouslySetInnerHTML={{ __html: maintenanceContent }} 
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          fontFamily: 'Arial, sans-serif',
          fontSize: '18px',
          textAlign: 'center',
          padding: '20px'
        }}
      />
    );
  }




  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/builder" element={
            isAuthenticated ? <PromptBuilderContainer /> : <Navigate to="/login" />
          } />
          <Route path="/login" element={
            isAuthenticated ? <Navigate to="/builder" /> : <Login />
          } />
          <Route path="/buy-credits" element={
            isAuthenticated ? <BuyCredits /> : <Navigate to="/login" />
          } />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/tutorials" element={<TutorialLayout />}>
            <Route index element={<TutorialContent tutorialId="introduction" />} />
            <Route path=":tutorialId" element={<TutorialContent />} />
          </Route>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
}




function App() {
  return (
    <AuthProvider>
      <NotificationProvider>
        <Router>
          <div className="App">
            <AppContent />
          </div>
        </Router>
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;